import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

//css
import * as styles from "./AboutUss.module.css";

const teamDetails = [
  {
    id: "ashok-jhunjhunwala",
    drName: "Dr Ashok Jhunjhunwala",
    designation: "Former Professor / IIT Madras & President, IITM Research Park",
    drImage: (
      <StaticImage
        src="../../images/aboutUs/ashok@2x.png"
        alt="Founder"
        placeholder="blurred"
      />
    ),
    content1:
      "Prof. Ashok Jhunjhunwala’s first appointment was in Washington State University, USA from 1979 to 1980. Prof. Jhunjhunwala then joined IIT Madras in 1981 in the Department of Electrical Engineering. Over the last few decades he has looked at the cost and affordability of various components of the telecommunications and the Internet network, especially in the Indian context. He has worked on techniques to enable communication networks to reach remote parts of rural India. In recent times, he has made some significant contributions in Solar-DC and in battery systems for Electric Vehicles. He has guided more than 70 M.S. and Ph.D. students.",
    content2:
      "Over the course of his career Jhunjhunwala has served as Chairman and member of various government committees. He has been on boards of several private companies and educational institutions in the country. He was on the boards of the State Bank of India, Bharat Electronics Ltd, Hindustan Teleprinters Limited, NRDC, Institute for Development and Research in Banking Technology (IDRBT), VSNL and BSNL as well as Tata Communications, Mahindra Electric, Sasken, Tejas Networks, Tata Teleservices Maharashtra Ltd, Intellect and Exicom. As of 2020 he is on the board of Biotechnology Industry Research Assistance Council (BIRAC), Chairman of IIITH and is the Chairman of Technology Advisory Committee of SEBI In 2017 - 18, Jhujhunwala was on sabbatical from IITM, serving as the Principal Advisor to the Ministry of Power in the Government of India, and the Ministry of Railways in the Government of India, New Delhi.",
  },
  {
    id: "rajiv-kumar",
    drName: "Dr Rajiv Kumar",
    designation: "Indian economist who had served as the second vice-chairman of the NITI Aayog",
    drImage: (
      <StaticImage
        src="../../images/aboutUs/rajiv@2x.png"
        alt="Founder"
        placeholder="blurred"
      />
    ),
    content1:
      "Dr. Rajiv Kumar, former Vice Chairman of NITI Aayog, recently resumed his chairmanship of Pahle India Foundation, a not-for-profit policy think tank, established by him in 2013. Dr. Kumar’s career has spanned academics to government to corporate chambers and multilateral institutions. The wide range of experience, combined with his intellectual depth, as reflected in his substantial publication record, gives him a unique multi-dimensional perspective on the Indian economy. He served in the government as Economic Advisor with the Department of Economic Affairs (Ministry of Finance) during 1991-1995 and took over as Vice Chairman of NITI Aayog in September 2017, with the rank of Cabinet Minister and served until April 2022.",
    content2:
      "His stints with the corporate sector include serving as Chief Economist of the Confederation of Indian Industries (CII) from 2004-2006 and later as Secretary General of the Federation of Indian Chambers of Commerce and Industry (FICCI), 2011-2013. Dr Kumar has a Ph.D. in Economics from Lucknow University (1978) and a D.Phil. from Oxford University (1982). He has been conferred with D.Litt. (honorary causa) by several universities.",
  },
  {
    id: "s-prakash",
    drName: "Dr S Prakash",
    designation: "MD Of Star health Insurance",
    drImage: (
      <StaticImage
        src="../../images/aboutUs/prakash@2x.png"
        alt="Founder"
        placeholder="blurred"
      />
    ),
    content1:
      "Dr S Prakash, M.S., FRCS (Glasg), FAIS, a passionate Medical Doctor who became Managing Director of a Dominant Health Insurance Company in India- Star Health Insurance. He is the first of its kind to have 2 decades of clinical experience from India & Overseas and close to 15 years of experience in Health Insurance Industry. During the past 14 years of his tenure, he grew from Medical Director, Executive Director, Chief Operating Officer, to Joint Managing Director and now elevated as a Managing Director. His efficiency in contributing to the functional growth of Star Health Insurance is reflected in the GWP growth of USD 3 million in 2007 to USD 1.47 billion in FY22.",
    content2: "He is the Chairperson of CII Tamil Nadu Healthcare Panel for consecutive 3 years. Currently, the Convenor of FICCI Tamil Nadu Insurance Panel, Executive Council Member of General Council of General and Health Insurers. Under his leadership the company has received many awards, the noteworthy one being ‘The World Health Day Award of Excellence’ from the Honourable President of India, Shri Pranab Mukherjee.",
  },
  {
    id: "krishna-g-seshadri-advisor",
    drName: "Dr. Krishna G Seshadri",
    designation: "Senior Consultant Endocrinology and Diabetes at Apollo Hospitals",
    company: "",
    drImage: (
      <StaticImage
        src="../../images/aboutUs/dr_krishna.png"
        alt="Founder"
        placeholder="blurred"
      />
    ),
    content1:
      "Dr. Krishna G Seshadri, an esteemed Endocrinologist with over 22 years of experience, practices at Apollo Hospitals Greams Road in Chennai. Dr. Seshadri, who holds an AB certification, specializes in a diverse array of endocrinology conditions. His expertise encompasses male hypogonadism, osteomalacia, glucocorticoid remediable aldosteronism, growth hormone deficiency, acute diarrhoea treatment, parathyroid diseases, pituitary diseases including pituitary dwarfism, Hba1c monitoring, ketosis-prone diabetes mellitus, pseudohypoparathyroidism, adrenal masses, alkaptonuria, menarche-related issues, Turner syndrome, familial hyperinsulinemic hypoglycemia, combined lipase deficiency, colloid goiter, and type 1 diabetes treatment. Dr. Seshadri offers comprehensive services to address these varied endocrinological concerns, ensuring high-quality care for his patients.",
    content2:
      "If you're seeking Endocrinologist in your neighbourhood then, Dr. Krishna G Seshadri is the right choice for expert care and treatment of your Endocrinology needs. Book an appointment today and experience top-notch medical care.",
  },
  {
    id: "manoj-chawla-advisor",
    drName: "Dr Manoj Chawla",
    designation: "Diabetologist, Executive Member, RSSDI",
    company: "",
    drImage: (
      <StaticImage
        src="../../images/aboutUs/dr_manoj.png"
        alt="Founder"
        placeholder="blurred"
      />
    ),
    content1:
      "Dr. Manoj Chawla is a Diabetologist. He has 20 years of experience in this field.He did MBBS from University of Mumbai, Mumbai in 1997 and a Diploma in Diabetes from University of Mumbai, Mumbai in 1999.He is a member of the Maharashtra Medical Council, India representative at IDF General Assembly. Dubai 2011, Postgraduate lecturer for CPS-Diabetology courses conducted 6 months at CPS, Mumbai, and Chairperson at the 1st Medtronic CGMS and Insulin Pump Conference, Chennai. He is also the Director and Consultant Diabetologist at Lina Diabetes Care & Mumbai Diabetes Research Centre (Andheri-West) and the Founder and President of United Diabetes Forum.",
    content2:
      "He was recently awarded the `Best Consultant Diabetologist in Mumbai` Award, in appreciation of his excellence in the field of Diabetes. Dr. Chawla has co-authored and presented research papers at various national and international symposia. He has been the Principal Investigator for various global clinical trials for new antidiabetic drugs and his special interest areas are CGMS and Insulin Pumps.",
  },
];

const Advisors = () => {
  return (
    <div className="bgWhiteColor">
      <div className="container">
        {/* heading */}
        <div className={`row ${styles.globalTeam__row1}`}>
          <div className={`col-12 ${styles.globalTeam__lineCol}`}></div>
          <div className={`col-12 ${styles.globalTeam__hdngCol}`}>
            <h3 className="text-align-center">Advisors</h3>
          </div>
        </div>
        <div className={`row ${styles.globalTeam__row2}`}>
          {teamDetails.map((item, index) => (
            <div key={index} className="col-6 col-md-3 col-lg-3">
              <div className="row">
                <div className="col-12 col-md-12 pb-md-3 d-flex justify-content-center">
                  <div className={`${styles.customImg} `}>{item.drImage}</div>
                </div>
                <div className="col-12 col-md-12 pt-3">
                  <div className="col-12">
                    <div className={`${styles.drName} pb-md-1 text-center`}>
                      {item.drName}
                    </div>
                  </div>
                  <div className="col-12 pt-1  pb-1">
                    <div className={`${styles.design} pb-md-1 text-center`}>
                      {item.designation}
                    </div>
                  </div>
                  <div className="col-12 pb-3 pb-md-1">
                    <div className="text-center">
                      <Link to={"/about-us-details/?name=" + item.id}>
                        <span
                          role="presentation"
                          className={`${styles.bio} cursorPointer`}
                        >
                          Bio
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className={`col-12 ${styles.globalTeam__spaceDiv}`}></div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Advisors;
